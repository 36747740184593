<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="modal-container md:h-10/12 md:w-10/12 lg:w-6/12 2xl:w-5/12 flex flex-col justify-center items-center font-proximaMedium"
        >
          <div class="flex flex-col items-center justify-center">
            <img src="@/assets/images/apple-icon.png" alt="apple-icon" />
            <h3 class="mt-4 text-2xl font-bold">New Nutrition Plan</h3>
            <FormError :errors="getError" />
            <FormError :errors="errorForm" />
          </div>

          <div class="w-full mt-3 flex flex-col items-center justify-center">
            <div
              class="mt-4 w-11/12 md:w-8/12 text-left font-proximaMedium text-sm"
            >
              <input
                type="text"
                placeholder="Enter nutrition plan name..."
                v-model="form.plan_name"
                class="w-full p-2 border-2 border-primary rounded-md m-auto"
              />
              <FormError :errors="errors.plan_name" />
            </div>
            <NewTag
              :tags="form.food_brands"
              placeholder="Enter food brand name..."
              @changeTag="changeTagFood"
              @deleteTag="deleteTagFood"
            />
            <NewTag
              :tags="form.ingredients"
              placeholder="Add ingredients..."
              @changeTag="changeTagIngredients"
              @deleteTag="deleteTagIngredients"
            />
          </div>
          <div class="flex items-start justify-start">
            <div class="flex">
              <span class="text-sm text-subheaderText mr-2"
                >Feeding Frequency</span
              >
              <div>
                <div class="mb-2 flex items-center">
                  <input
                    type="number"
                    v-model="form.feeding_times"
                    class="w-20 p-2 mr-2 border border-primary rounded-md"
                  />
                  <span class="font-proximaLight text-sm">times</span>
                </div>
                <div class="flex items-center">
                  <InputRadioEach
                    title="daily"
                    value="daily"
                    :name="form.feeding_frequency"
                    @inputVal="emitFrequent"
                  />
                  <InputRadioEach
                    title="weekly"
                    class="mx-4"
                    value="weekly"
                    :name="form.feeding_frequency"
                    @inputVal="emitFrequent"
                  />
                  <InputRadioEach
                    title="monthly"
                    value="monthly"
                    :name="form.feeding_frequency"
                    @inputVal="emitFrequent"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="w-8/12 mt-6 flex items-start justify-start">
            <div class="flex">
              <span class="text-sm text-subheaderText mr-6"
                >Feeding Amount</span
              >
              <div class="mr-8 flex flex-col items-center">
                <input
                  type="number"
                  v-model="form.feeding_amount"
                  class="w-20 p-2 mb-2 border border-primary rounded-md"
                />
                <InputRadioEach
                  title="grams"
                  value="grams"
                  :name="form.measurement"
                  @inputVal="emitAmount"
                />
              </div>
              <div class="flex flex-col items-center">
                <input
                  type="text"
                  v-model="feedingAmountCustom"
                  class="w-20 p-2 mb-2 border border-primary rounded-md"
                />
                <InputRadioEach
                  title="custom"
                  value="custom"
                  :name="form.measurement"
                  @inputVal="emitAmount"
                />
              </div>
            </div>
          </div>
          <FormError :errors="errors.feeding_amount" />
          <div class="mt-12 w-full md:w-8/12 flex justify-evenly">
            <div class="mx-auto w-10/12 md:w-5/12 relative">
              <BorderedButton
                class="mx-auto"
                marginT="mt-2"
                :title="$t('global_cancel')"
                paddingX="px-6"
                width="w-full"
                @click.native="closeNewNutritionModals"
              />
            </div>
            <div class="mx-auto w-10/12 md:w-5/12 relative">
              <SubmitButton
                class="mx-auto"
                marginT="mt-2"
                :title="$t('global_confirm')"
                background="bg-success"
                @click.native="saveSubmit"
                paddingX="px-6"
                width="w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import NewTag from "./NewTag.vue";
import FormError from "../../warning/FormError.vue";
import TrashIcon from "@/components/icons/TrashIcon.vue";
import AddIcon from "../../icons/AddIcon.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import InputRadioEach from "../../forms/InputRadioEach_.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    show: Boolean,
  },
  components: {
    SubmitButton,
    BorderedButton,
    TrashIcon,
    FormError,
    NewTag,
    AddIcon,
    ModalsConfirm,
    InputRadioEach,
  },
  data() {
    return {
      form: {
        plan_name: "",
        food_brands: [],
        ingredients: [],
        feeding_times: 0,
        feeding_frequency: "daily",
        feeding_amount: "",
        measurement: "grams",
        userEmailAddress: localStorage.getItem("email"),
        petID: "",
      },
      errors: {},
      feedingAmountGrams: "",
      feedingAmountCustom: "",
    };
  },
  created() {
    var retrievedObject = localStorage.getItem("pet");

    var pet = JSON.parse(retrievedObject);
    this.form.petID = pet.petID;
  },

  computed: {
    ...mapGetters({
      getError: "medicalhistory/getError",
    }),
    errorForm() {
      return this.$store.state.errorForm;
    },
  },

  methods: {
    ...mapActions({
      addNutrition: "medicalhistory/addNutrition",
    }),

    emptyField() {
      this.tags = [];
      this.errors = {};
    },
    emitFrequent(i, e) {
      console.log(e);
      this.form.feeding_frequency = e;
    },
    emitAmount(i, e) {
      console.log(e);
      this.form.measurement = e;
    },
    changeTagFood(val) {
      val.split(",").map((line) => {
        if (
          val[val.length - 1] == "," &&
          this.form.food_brands.findIndex((obj) => obj === line) == -1 &&
          line != ""
        ) {
          this.form.food_brands.push(line);
        }
      });
    },
    deleteTagFood(val) {
      const diags = this.form.food_brands.filter((c) => c !== val);
      this.form.food_brands = diags;
    },
    changeTagIngredients(val) {
      val.split(",").map((line) => {
        if (
          val[val.length - 1] == "," &&
          this.form.ingredients.findIndex((obj) => obj === line) == -1 &&
          line != ""
        ) {
          this.form.ingredients.push(line);
        }
      });
    },
    deleteTagIngredients(val) {
      const diags = this.form.ingredients.filter((c) => c !== val);
      this.form.ingredients = diags;
    },
    closeNewNutritionModals() {
      this.$emit("closeNewNutritionModals", false);
    },

    validate() {
      const errors = {};

      if (this.form.plan_name.trim() === "")
        errors.plan_name =
          "Nutrition plan name " + this.$t("global_is_required");
      if (this.form.feeding_amount.trim() === "")
        errors.feeding_amount =
          "Feeding Amount " + this.$t("global_is_required");

      return Object.keys(errors).length === 0 ? null : errors;
    },

    saveSubmit() {
      this.$store.commit("errorForm", "");

      const errors = this.validate();
      this.errors = errors || {};
      if (errors) return;
      console.log(this.form);

      /* if (!this.form.date_applied) {
        this.$store.commit("errorForm", "Applied is required");
        return;
      } */

      this.addNutrition(this.form);
    },
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  max-height: 90vh; /* Set max height to 80% of viewport height */
  overflow-y: auto;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
