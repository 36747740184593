<template>
  <div class="my-6">
    <NutritionPlanSection />
  </div>
</template>

<script>
import NutritionPlanSection from "../components/nutritionplan/NutritionPlanSection.vue";

export default {
  components: {
    NutritionPlanSection
  },
  created() {
    
  },
};
</script>