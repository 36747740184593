<template>
  <div class="w-full md:w-10/12 overflow-x-auto">
    <ul
      class="bg-white p-4 text-primary font-proximaMedium rounded-sm flex items-center"
    >
      <li class="w-4/12 text-left">Plan Version</li>
      <li class="w-2/12">Created</li>
      <li class="w-6/12 text-left">Changes Made</li>
    </ul>
    <ul
      v-for="(data, i) in nutritions"
      :key="i"
      class="py-6 border-b border-primary text-sm font-proximaLight flex items-center justify-between"
    >
      <li
        class="w-4/12 px-4 flex items-center justify-between"
        :class="{ 'text-primary': grayed }"
      >
        <div>{{ data.plan_version || data.plan_name }}</div>
        <div>
          <img
            src="@/assets/images/edit-icon.png"
            alt="edit-icon"
            class="cursor-pointer"
            @click="openEditNutritionModals()"
          />
        </div>
      </li>

      <li class="w-2/12" :class="{ 'text-lightGrey': grayed }">
        {{ data.date_created.substr(0, 10) }}
      </li>
      <li class="w-6/12 text-left" :class="{ 'text-lightGrey': grayed }">
        {{ data.changes_made }}
      </li>
    </ul>

    <AddIcon
      @click.native="openNewNutritionModals()"
      class="mt-8 cursor-pointer"
      width="62"
      height="62"
    />

    <NewNutritionModals
      :show="showNutritionModalsComp"
      @closeNewNutritionModals="closeNewNutritionModals"
    />
    <EditNutritionModals
      :show="showEditNutritionModalsComp"
      @closeEditNutritionModals="closeEditNutritionModals"
    />
  </div>
</template>

<script>
import TrashIcon from "../../icons/TrashIcon.vue";
import AddIcon from "../../icons/AddIcon.vue";
import NewNutritionModals from "./NewNutritionModals.vue";
import EditNutritionModals from "./EditNutritionModals.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    TrashIcon,
    AddIcon,
    NewNutritionModals,
    EditNutritionModals,
  },
  data() {
    return {
      grayed: true,
    };
  },
  computed: {
    ...mapGetters({
      getError: "medicalhistory/getError",
      nutritions: "medicalhistory/getNutritions",
    }),

    showNutritionModalsComp() {
      return this.$store.state.showNutritionModalsState;
    },
    showEditNutritionModalsComp() {
      return this.$store.state.showEditNutritionModalsState;
    },
  },
  created() {
    var retrievedObject = localStorage.getItem("pet");

    var pet = JSON.parse(retrievedObject);

    this.getNutritionPlanOfPet(pet.petID);
  },

  methods: {
    ...mapActions({
      getNutritionPlanOfPet: "medicalhistory/getNutritionPlanOfPet",
      deleteVaccination: "medicalhistory/deleteVaccination",
    }),

    closeNewNutritionModals() {
      this.$store.commit("showNutritionModals", false);
    },
    openNewNutritionModals() {
      this.$store.commit("showNutritionModals", true);
    },
    closeEditNutritionModals() {
      this.$store.commit("showEditNutritionModals", false);
    },
    openEditNutritionModals() {
      this.$store.commit("showEditNutritionModals", true);
    },
  },
};
</script>

<style></style>