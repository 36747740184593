<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 flex flex-col justify-center items-center font-proximaMedium overflow-y-auto"
        >
          <div class="flex flex-col items-center justify-center">
            <img src="@/assets/images/apple-icon.png" alt="apple-icon" />
            <h3 class="mt-4 text-2xl font-bold">Edit Nutrition Plan</h3>
          </div>
          <div class="w-full mt-3 flex flex-col items-center justify-center">
            <NewTag
              :tags="tagsFood"
              placeholder="Enter food brand name..."
              @changeTag="changeTagFood"
              @deleteTag="deleteTagFood"
            />
            <NewTag
              :tags="tagsIngredients"
              placeholder="Add ingredients..."
              @changeTag="changeTagIngredients"
              @deleteTag="deleteTagIngredients"
            />
          </div>
          <div class="flex items-start justify-start">
            <div class="flex">
              <span class="text-sm text-subheaderText mr-2"
                >Feeding Frequency</span
              >
              <div>
                <div class="mb-2 flex items-center">
                  <input
                    type="number"
                    v-model="feedingTimes"
                    class="w-20 p-2 mr-2 border border-primary rounded-md"
                  />
                  <span class="font-proximaLight text-sm">times</span>
                </div>
                <div class="flex items-center">
                  <InputRadioEach title="daily" @inputVal="emitFrequent" />
                  <InputRadioEach
                    title="weekly"
                    class="mx-4"
                    @inputVal="emitFrequent"
                  />
                  <InputRadioEach title="monthly" @inputVal="emitFrequent" />
                </div>
              </div>
            </div>
          </div>
          <div class="w-8/12 mt-6 flex items-start justify-start">
            <div class="flex">
              <span class="text-sm text-subheaderText mr-6"
                >Feeding Amount</span
              >
              <div class="mr-8 flex flex-col items-center">
                <input
                  type="number"
                  v-model="feedingAmountGrams"
                  class="w-20 p-2 mb-2 border border-primary rounded-md"
                />
                <InputRadioEach title="grams" @inputVal="emitAmount" />
              </div>
              <div class="flex flex-col items-center">
                <input
                  type="text"
                  v-model="feedingAmountCustom"
                  class="w-20 p-2 mb-2 border border-primary rounded-md"
                />
                <InputRadioEach title="custom" @inputVal="emitAmount" />
              </div>
            </div>
          </div>
          <div class="mt-12 w-full md:w-8/12 flex justify-evenly">
            <div class="mx-auto w-10/12 md:w-5/12 relative">
              <BorderedButton
                class="mx-auto"
                marginT="mt-2"
                :title="$t('global_cancel')"
                paddingX="px-6"
                width="w-full"
                @click.native="closeEditNutritionModals"
              />
            </div>
            <div class="mx-auto w-10/12 md:w-5/12 relative">
              <SubmitButton
                class="mx-auto"
                marginT="mt-2"
                :title="$t('global_confirm')"
                background="bg-success"
                paddingX="px-6"
                width="w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import NewTag from "./NewTag.vue";
import FormError from "../../warning/FormError.vue";
import TrashIcon from "@/components/icons/TrashIcon.vue";
import AddIcon from "../../icons/AddIcon.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import InputRadioEach from "../../forms/InputRadioEach.vue";

export default {
  props: {
    show: Boolean,
    foodBrand: Array,
    foodIngredients: Array,
  },
  components: {
    SubmitButton,
    BorderedButton,
    TrashIcon,
    FormError,
    NewTag,
    AddIcon,
    ModalsConfirm,
    InputRadioEach,
  },
  data() {
    return {
      newNutritionModals: false,
      tagsFood: [],
      tagsIngredients: [],
      errors: {},
      optionFrequent: "",
      optionAmount: "",
      feedingTimes: "",
      feedingAmountGrams: "",
      feedingAmountCustom: "",
    };
  },
  mounted() {},
  methods: {
    emptyField() {
      this.tags = [];
      this.errors = {};
    },
    emitFrequent(i, e) {
      this.optionFrequent = e;
    },
    emitAmount(i, e) {
      this.optionAmount = e;
    },
    changeTagFood(val) {
      val.split(",").map((line) => {
        if (
          val[val.length - 1] == "," &&
          this.tagsFood.findIndex((obj) => obj === line) == -1 &&
          line != ""
        ) {
          this.tagsFood.push(line);
        }
      });
    },
    deleteTagFood(val) {
      const diags = this.tagsFood.filter((c) => c !== val);
      this.tagsFood = diags;
    },
    changeTagIngredients(val) {
      val.split(",").map((line) => {
        if (
          val[val.length - 1] == "," &&
          this.tagsIngredients.findIndex((obj) => obj === line) == -1 &&
          line != ""
        ) {
          this.tagsIngredients.push(line);
        }
      });
    },
    deleteTagIngredients(val) {
      const diags = this.tagsIngredients.filter((c) => c !== val);
      this.tagsIngredients = diags;
    },
    closeEditNutritionModals() {
      this.$emit("closeEditNutritionModals", false);
    },
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
